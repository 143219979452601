import { colors, constants, spacing } from '@src/Styles'
import SearchIcon from '@mui/icons-material/Search'
import { useTypographyContext } from '@src/Typography'
import { InputAdornment, TextField } from '@mui/material'

const ICON_HEIGHT = 24

const SearchInput = ({
  placeholder,
  search,
  setSearch,
}: {
  placeholder: string
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { scale, typography } = useTypographyContext()

  return (
    <TextField
      sx={{
        width: '100%',
        background: colors.white[100],
        borderRadius: `${constants.borderRadius.medium}px`,
        '.MuiOutlinedInput-input': {
          display: 'flex',
          alignItems: 'center',
        },
        fieldset: {
          // eslint-disable-next-line lingui/no-unlocalized-strings
          border: `1px solid ${colors.neutrals[50]}`,
          borderRadius: `${constants.borderRadius.medium}px`,
        },
        input: {
          ...typography.title.mediumRegular,
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              sx={{
                fontSize: ICON_HEIGHT * scale,
                color: colors.neutrals[400],
              }}
            />
          </InputAdornment>
        ),
        endAdornment:
          search.length > 0 ? (
            <InputAdornment position="end">
              <button
                type="reset"
                onClick={() => setSearch('')}
                css={{
                  paddingInline: spacing.base * scale,
                  background: colors.white[600],
                  border: `1px solid ${colors.transparent}`,
                  borderRadius: '50%',
                  whiteSpace: 'nowrap',
                  ...typography.button.medium,
                  cursor: 'pointer',
                  ':hover:enabled': {
                    borderColor: colors.neutrals[400],
                  },
                  ':active:enabled': {
                    background: colors.neutrals[400],
                    color: colors.neutrals[50],
                  },
                  ':disabled': {
                    opacity: constants.opacity.quarter,
                    borderColor: colors.neutrals[100],
                    cursor: 'not-allowed',
                  },
                }}
              >
                {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
                {'x'}
              </button>
            </InputAdornment>
          ) : null,
      }}
      placeholder={placeholder}
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
  )
}

export default SearchInput
