import { Trans } from '@lingui/macro'
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined'
import { spacing } from '@src/Styles'

const OrdersEmpty = () => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: spacing.base,
        }}
      >
        <RemoveShoppingCartOutlinedIcon css={{ height: 48, width: 48 }} />
        <div>
          <Trans>No orders to display</Trans>
        </div>
      </div>
    </div>
  )
}

export default OrdersEmpty
