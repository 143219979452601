import { useState } from 'react'
import Link from 'next/link'
import { msg, Trans } from '@lingui/macro'
import { MessageDescriptor } from '@lingui/core'
import PersonIcon from '@mui/icons-material/Person'
import HomeIcon from '@mui/icons-material/Home'
import {
  CmaOrderFirstdData,
  CsbFirstdStatus,
  CsbOrder,
} from '@kjt01/greendot-wasm'
import Modal from '@src/Modal'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import { getUsd, parseCurrency, serializeCurrency } from '@src/Currency/helpers'
import { formatPhone } from '@src/Help/helpers'
import {
  formatOrderCardAddress,
  formatOrderNumber,
  getGoogleMapsURL,
  getOrderRootWid,
  getOrderVersion,
} from '@src/Order/helpers'
import { useLingui } from '@lingui/react'
import { environment } from '@src/Order/OrderCustomer'

const ORDER_STATUS: Record<
  CsbFirstdStatus,
  { background: string; color: string; label: MessageDescriptor }
> = {
  order_placed: {
    background: colors.neutrals[50],
    color: colors.neutrals[500],
    label: msg`Order Placed`,
  },
  driver_assigned: {
    background: colors.modifiers[50],
    color: colors.modifiers[700],
    label: msg`Preparing Order`,
  },
  driver_auto_reassigned: {
    background: colors.primary2[50],
    color: colors.primary2[500],
    label: msg`Preparing Order`,
  },
  order_picked_up: {
    background: colors.primary2[50],
    color: colors.primary2[500],
    label: msg`Order Pickup`,
  },
  order_en_route: {
    background: colors.variants[100],
    color: colors.variants[700],
    label: msg`Order En Route`,
  },
  order_at_dropoff: {
    background: colors.variants[100],
    color: colors.variants[700],
    label: msg`Order Drop Off`,
  },
  completed: {
    background: colors.dish[50],
    color: colors.dish[700],
    label: msg`Completed`,
  },
  cancelled: {
    background: colors.error[200],
    color: colors.error[700],
    label: msg`Cancelled`,
  },
}

const OrderCard = ({
  deliveryStatus,
  order,
}: {
  deliveryStatus: CmaOrderFirstdData | null
  order: CsbOrder
}) => {
  const { i18n } = useLingui()
  const { typography, scale } = useTypographyContext()

  const [isMapModalOpen, setIsMapModalOpen] = useState(false)

  if (order.ticket_number == null) return null

  const isVoided = order.order_metadata?.order_status === 'voided'

  const totalTip = order.order_checks
    .flatMap((check) => check.wprop.tip)
    .reduce((total, curr) => {
      if (total == null || curr == null) return total
      return total + parseCurrency(curr)
    }, parseCurrency(serializeCurrency()))

  return (
    <>
      <Link
        href={`/orders/${order.ticket_number}?wid=${getOrderRootWid(order)}&vid=${getOrderVersion(order)}`}
        css={{ textDecoration: 'none', display: 'grid' }}
        shallow={true}
      >
        <div
          css={{
            width: '100%',
            minWidth: 320,
            border: `1px solid ${
              isVoided ? colors.error[100] : colors.neutrals[50]
            }`,
            borderRadius: constants.borderRadius.medium,
            background: isVoided ? colors.error[50] : colors.white[50],
            ':hover': {
              background: isVoided ? colors.error[100] : colors.primary2[50],
              borderColor: isVoided ? colors.error[500] : colors.primary2[500],
              '#pill': {
                background: isVoided ? colors.error[200] : colors.dish[50],
              },
            },
            color: colors.neutrals[500],
            ':active': {
              color: colors.white[100],
              background: isVoided ? colors.error[500] : colors.primary2[500],
              borderColor: isVoided ? colors.error[500] : colors.primary2[500],
            },
            cursor: 'pointer',
          }}
        >
          {environment === 'production' && (
            <div
              css={{
                height: 24,
                padding: spacing.small,
                ...(deliveryStatus != null
                  ? {
                      background:
                        ORDER_STATUS[deliveryStatus.current_status].background,
                      color: ORDER_STATUS[deliveryStatus.current_status].color,
                    }
                  : {}),
                borderTopLeftRadius: constants.borderRadius.medium,
                borderTopRightRadius: constants.borderRadius.medium,
                textTransform: 'uppercase',
                ...typography.body.small,
                textAlign: 'center',
              }}
            >
              {deliveryStatus != null && (
                <>{i18n._(ORDER_STATUS[deliveryStatus.current_status].label)}</>
              )}
            </div>
          )}
          <div css={{ padding: spacing.normal }}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: spacing.base,
              }}
            >
              <div
                css={{
                  ...typography.title.large,
                  ...(isVoided ? { textDecoration: 'line-through' } : {}),
                }}
              >
                {formatOrderNumber(order)}
              </div>
              <div
                css={{
                  display: 'flex',
                  gap: spacing.small,
                  alignItems: 'end',
                }}
              >
                <div
                  id="pill"
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: spacing.base,
                    paddingInline: spacing.base,
                    background: isVoided ? colors.error[100] : colors.dish[50],
                    borderRadius: constants.borderRadius.xxlarge,
                  }}
                >
                  <div
                    css={{
                      ...typography.title.medium,
                      color: colors.neutrals[500],
                      ...(isVoided ? { textDecoration: 'line-through' } : {}),
                    }}
                  >
                    {(order.client_display_total.kind === 'order_total' ||
                      order.client_display_total.kind ===
                        'compensated_reorder_de' ||
                      order.client_display_total.kind ===
                        'compensated_reorder_self_delivery' ||
                      order.client_display_total.kind ===
                        'compensated_reorder_pickup') &&
                      getUsd(order.client_display_total.payload)}
                    {order.has_credit_payment === true ? '*' : null}
                    {order.tarro_handled_payments.length > 0 ? '**' : null}
                  </div>
                </div>
              </div>
            </div>

            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: spacing.base,
                ...typography.title.small,
              }}
            >
              <div
                css={{
                  minHeight: 28,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  ...typography.title.small,
                  ...(isVoided ? { textDecoration: 'line-through' } : {}),
                }}
              >
                {order.service_type === 'pickup' && <Trans>Pickup</Trans>}
                {order.service_type === 'delivery' &&
                  deliveryStatus != null && (
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingBlock: spacing.small,
                        paddingInline: spacing.base,
                        borderRadius: constants.borderRadius.small,
                        background: colors.wondersBlue[50],
                      }}
                    >
                      {deliveryStatus.driver_name == null ? (
                        <PersonIcon
                          sx={{
                            height: 15 * scale,
                            width: 'auto',
                            color: colors.neutrals[300],
                          }}
                        />
                      ) : (
                        <div>{deliveryStatus.driver_name}</div>
                      )}
                    </div>
                  )}
              </div>
              {totalTip > 0 && order.tarro_handled_payments.length === 0 ? (
                <div>
                  +
                  <span css={{ fontWeight: 700, paddingRight: spacing.small }}>
                    {getUsd(totalTip)}
                  </span>
                  <Trans>Tip</Trans>
                </div>
              ) : null}
            </div>
            <div css={{ display: 'flex' }}>
              <div
                css={{
                  flex: 1,
                  overflow: 'hidden',
                }}
              >
                {order.service_type === 'delivery' &&
                  order.order_metadata?.delivery_complex?.delivery_address !=
                    null && (
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <HomeIcon
                        sx={{
                          height: 15 * scale,
                          width: 'auto',
                          color: colors.primary2[500],
                        }}
                      />

                      <button
                        css={{
                          overflow: 'hidden',
                          textWrap: 'nowrap',
                          textOverflow: 'ellipsis',
                          background: 'none',
                          color: colors.neutrals[500],
                          border: 'none',
                          ':hover': {
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          },
                          ...typography.label.medium,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          setIsMapModalOpen(true)
                        }}
                      >
                        {formatOrderCardAddress(order)}
                      </button>
                    </div>
                  )}
              </div>
              {order.service_type === 'pickup' && (
                <div
                  css={{
                    color: colors.neutrals[500],
                    ...typography.label.medium,
                  }}
                >
                  {formatPhone(
                    order.customer_idn_complex.phone_complex
                      ?.contact_phone_number ??
                      order.customer_idn_complex.phone_complex?.phone_number ??
                      '',
                  )}
                </div>
              )}
            </div>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: spacing.base,
                ...typography.label.small,
              }}
            >
              {order.tarro_handled_payments.length > 0 && (
                <div css={{}}>
                  **<Trans>Compensated by Tarro</Trans>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
      <Modal
        header={<Trans>Redirect to Google Maps</Trans>}
        subheader={<Trans>You will redirect to Google Maps.</Trans>}
        isOpen={isMapModalOpen}
        isSubmitting={false}
        isDisabled={false}
        onClose={() => setIsMapModalOpen(false)}
        onCancel={() => setIsMapModalOpen(false)}
        onConfirm={() => {
          const url = getGoogleMapsURL(order)
          if (url != null) {
            window.open(url, '_blank')
          }
          setIsMapModalOpen(false)
        }}
        confirmLabel={<Trans>Yes. Open Google Maps</Trans>}
      />
    </>
  )
}

export default OrderCard
